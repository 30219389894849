<template>
  <li :class="['dropdown-container', { border: !headerBorder && !mainHeading && !subHeading }]">
    <button
      :ref="`list${uniqueID}`"
      :id="uniqueID + '-header'"
      :aria-controls="uniqueID + '-panel'"
      :aria-expanded="isExpanded ? 'true' : 'false'"
      @click="toggle"
      class="accordion-trigger"
      :class="[{ 'sub-heading': subHeading }, { width100: mainHeading }, { border: headerBorder }]"
    >
      <template v-if="!mainHeading">
        <span aria-hidden="true" v-if="!isExpanded" class="plus">+</span>
        <span aria-hidden="true" v-else class="minus">-</span>
        {{ title }}
      </template>
      <template v-else>
        <div class="mobile-faqs-heading">
          <h2 class="main-heading">{{ title }}</h2>
          <span aria-hidden="true" v-if="!isExpanded" class="plus2">+</span>
          <span aria-hidden="true" v-else class="minus2">-</span>
        </div>
      </template>
    </button>
    <div class="accordion-content">
      <transition @enter="enter" @after-enter="afterEnter" @leave="leave" name="slide">
        <div
          :id="uniqueID + '-panel'"
          :aria-labelledby="uniqueID + '-header'"
          v-show="isExpanded"
          class="accordion-content__text"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </li>
</template>
<script>
export default {
  name: 'AccordionItem',
  props: {
    title: { type: String, required: true },
    headerBorder: { type: Boolean, required: false },
    mainHeading: { type: Boolean, required: false },
    subHeading: { type: Boolean, required: false },
    onTrigger: { type: Function, required: false, default: null },
  },
  data() {
    return {
      uniqueID: Math.random().toString(36).substring(2, 15),
      isExpanded: false,
      accordionID: this.$parent.accordionID,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
  methods: {
    toggle() {
      if (this.onTrigger) {
        this.onTrigger();
      }

      if (this.isExpanded) {
        this.isExpanded = false;
      } else {
        this.$root.$emit('toggleAccordion', {
          accordionID: this.accordionID,
          uniqueID: this.uniqueID,
        });
      }
    },
    enter(elParam) {
      const element = elParam;
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.height = 'auto';

      const { height } = getComputedStyle(element);
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // eslint-disable-next-line
      getComputedStyle(element).height;

      this.$nextTick(() => {
        element.style.height = height;
        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
      });
    },
    afterEnter(elParam) {
      const element = elParam;
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 'auto';
    },
    leave(elParam) {
      const element = elParam;
      const { height } = getComputedStyle(element);
      element.style.height = height;

      // eslint-disable-next-line
      getComputedStyle(element).height;

      this.$nextTick(() => {
        element.style.height = 0;
      });
    },
  },
  mounted() {
    this.$root.$on('refreshAccordion', (data) => {
      if (this.accordionID === data.accordionID) {
        if (this.uniqueID === data.uniqueID) {
          this.isExpanded = true;
        } else {
          this.isExpanded = false;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  overflow: hidden;
  cursor: pointer;

  button {
    color: $c-primary;
    margin: 0;
    padding: 0;
    padding: 14px 0 14px 0;
    width: 100%;
    padding-left: 5px;
    &.border {
      border-bottom: 1px solid $c-border-blue;
      width: 100%;
    }
    &:focus {
      outline: none;
      border-radius: 4px;
      outline: 3px solid $c-focus;
      outline-offset: -3px;
    }
  }
  button:not(.main-heading):not(.sub-heading) {
    font-weight: 600;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: height 0.3s ease-in-out;
  }
  .slide-enter,
  .slide-leave-to {
    height: 0;
  }
  &.border {
    border-bottom: 1px solid $c-border-blue;
  }
}
.accordion-content {
  padding-top: 0px;
  ::marker {
    font-size: 16px;
  }
  @media (min-width: 750px) {
    padding-top: 16px;
    padding-left: 30px;
  }
  &__text {
    p {
      font-size: 13px !important;
      @media (min-width: 750px) {
        font-size: 16px;
      }
    }
  }
}
.accordion-trigger {
  display: flex;
  background: transparent;
  font-size: 13px;
  font-family: inherit;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: inherit;
  color: inherit;
  @media (min-width: 750px) {
    font-size: 24px;
  }
  .plus,
  .minus {
    min-width: 30px;
    display: inline-block;
  }
}
//! Note: changes for AggregateFAQ page, as it has different style for heading
.main-heading {
  margin: 0;
  flex: 1;
  font-weight: 600;
  font-size: 24px;
  @media (min-width: 750px) {
    font-size: 42px;
  }
}
.sub-heading {
  font-weight: $fw-medium;
  line-height: 1;
  font-size: 18px;
}
.width100 {
  width: 100%;
}
.plus2,
.minus2 {
  text-align: right;
  font-weight: $fw-light;
  font-size: 24px;
  @media (min-width: 750px) {
    font-size: 50px;
  }
}
.mobile-faqs-heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
